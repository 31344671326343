import { render, staticRenderFns } from "./user-left-room.html?vue&type=template&id=28e1afa6&scoped=true&external"
import script from "./user-left-room.js?vue&type=script&lang=js&external"
export * from "./user-left-room.js?vue&type=script&lang=js&external"
import style0 from "./user-left-room.scss?vue&type=style&index=0&id=28e1afa6&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28e1afa6",
  null
  
)

export default component.exports